import {Link} from "gatsby";
import React, {useState} from "react";
import {Form} from "react-bootstrap";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import ContactCard from "../ContactCard/ContactCard";
import client from "../../images/person.jpg";
import PlayVideo from '../Play/PlayVideo';
import "./CalculatorForm.scss";
import MortgageCalculator from "./MortgageCalculator";
import StampdutyCalc from "./StampDutyCalc";
const formField = [
  {
    labelName: "Purchase Price (£):",
    inputValue: "£4,950,000"
  }, {
    labelName: "Deposit Available (£):",
    inputValue: "£495,000"
  }, {
    labelName: "Duration (Years):",
    inputValue: "25"
  }, {
    labelName: "Interest Rate (%):",
    inputValue: "£4,950,000"
  }
]
const blockCardList = [
  {
    blockImg: client,
    blockTitle: 'Speak with our mortgage partner',

    blockContent: '',        //update here for toby's record/
    blockBtn: 'Get mortgage help',
    blockLink: '#',
    blockVideo: 'V8ksSGxo6no'
  }
]
const CalculatorForm = (props) => {
    const [isPlay, setPlay] = useState(false);
    return (
        <React.Fragment>
            <div className="form-block">
                {props.calc == "mortgage" && <div className="form-heading">
                    <h2>Mortgage Calculator</h2>
                    <p>Calculate your monthly mortgage payments.</p>
                </div>}
                {props.calc == "stamp-duty" && <div className="form-heading">
                    <h2>Stamp Duty Calculator</h2>
                    <p>Find out how much you will have to pay by using our Stamp Duty Calculator.</p>
                </div>}
                {props.calc == "mortgage" && 
                    <MortgageCalculator
                        property={props?.data}
                        {...props}
                    />
                }
                {props.calc == "stamp-duty" && 
                    <StampdutyCalc
                        property={props?.data}
                        propertyType="residential"
                        propertyValue={props?.data?.price}
                        buyerType="first"
                        currency="£"
                    />
                }
                <ContactCard
                    blockImg={props?.mortgage?.Tile_Image?.url}
                    blockTitle={props?.mortgage?.Title}
                    blockContent={props?.mortgage?.Description}
                    blockBtn={null}
                    blockLink={null}
                    blockVideo={props?.mortgage?.Video_Url}
                />
            </div>
            <PlayVideo isOpen={isPlay} stopPlay={setPlay} videoId="V8ksSGxo6no"/>
        </React.Fragment>
    );
};
export default CalculatorForm;
