import HTMLReactParser from "html-react-parser";
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import BrandLogo from "../../images/logo.svg";
import "./print.scss";

const PrintPropertyDetails = (props) => {
    return (
        <Container className="mt-0">
            <Row className="mx-2" justify="center" style={{ pageBreakAfter: "always", position: "relative" }}>
                <Col xs={12} className="text-center p-5" >
                    <img src={BrandLogo} width={100}/>
                </Col>
                <hr/>
                <Col xs={9} className="h-100 d-flex flex-column justigy-content-between">
                    <img src={props?.data?.images?.[0]?.url} width={"100%"}/>
                </Col>
                <Col xs={3} className="h-100 d-flex flex-column justigy-content-between">
                    <img src={props?.data?.images?.[1]?.url} width={"100%"}/>
                    <br/>
                    <img src={props?.data?.images?.[2]?.url} width={"100%"}/>
                    <br/>
                    <img src={props?.data?.images?.[3]?.url} width={"100%"}/>
                </Col>
                {props?.data?.long_description && <Col xs={12} className="py-2 text-center">
                    <h1 className="pt-1 mb-0"><b>{props?.data?.intro}</b></h1>
                    <h4 className="pb-2"><b>£ {props?.data?.price.toLocaleString()} {props?.data?.status}</b></h4>
                    <p>
                        {HTMLReactParser(props?.data?.long_description)}
                    </p>
                </Col>}
                <Col xs={12} className="text-center print-footer">
                    <p className="pt-3 mb-0"><b>{props?.data?.display_address}</b></p>
                </Col>
            </Row>
            <Row justify="center" className="mx-2"  style={{ pageBreakAfter: "always", position: "relative" }}>
                {props?.data?.long_description && <Col xs={11} className="py-2 text-center">
                    <h1 className="pt-1 mb-0"><b>{props?.data?.intro}</b></h1>
                    <h4 className="pb-2"><b>£ {props?.data?.price.toLocaleString()} {props?.data?.status}</b></h4>
                </Col>}
                {props?.data?.images?.slice(0, 6).map((each, i) => 
                    <Col xs={6} className="h-100 d-flex flex-column justigy-content-between py-2" key={i}>
                        <img src={each.url} width={"100%"}/>
                    </Col>
                )}
            </Row>
            {props?.data?.images?.length > 6 && <Row justify="center" className="mx-2"  style={{ pageBreakAfter: "always", position: "relative" }}>
                {props?.data?.long_description && <Col xs={11} className="py-2 text-center">
                    <h1 className="pt-1 mb-0"><b>{props?.data?.intro}</b></h1>
                    <h4 className="pb-2"><b>£ {props?.data?.price.toLocaleString()} {props?.data?.status}</b></h4>
                </Col>}
                {props?.data?.images?.slice(6, 12).map((each, i) => 
                    <Col xs={6} className="h-100 d-flex flex-column justigy-content-between py-2" key={i}>
                        <img src={each.url} width={"100%"}/>
                    </Col>
                )}
            </Row>}
            {props?.data?.images?.length > 12 && <Row justify="center" className="mx-2"  style={{ pageBreakAfter: "always", position: "relative" }}>
                {props?.data?.long_description && <Col xs={11} className="py-2 text-center">
                    <h1 className="pt-1 mb-0"><b>{props?.data?.intro}</b></h1>
                    <h4 className="pb-2"><b>£ {props?.data?.price.toLocaleString()} {props?.data?.status}</b></h4>
                </Col>}
                {props?.data?.images?.slice(12, 18).map((each, i) => 
                    <Col xs={6} className="h-100 d-flex flex-column justigy-content-between py-2" key={i}>
                        <img src={each.url} width={"100%"}/>
                    </Col>
                )}
            </Row>}
        </Container>
    )
}

export default PrintPropertyDetails;