import React, {useState} from 'react';
import CalculatorForm from '../CalculatorForm/CalculatorForm';
import {Modal} from "react-bootstrap";
import './ProductCalculator.scss';
import _ from 'lodash';

const ProductCalculator = (props) => {
    const [show, setShow] = useState(false);
    const [calc, setCalc] = useState("");
    const handleClose = () => setShow(false)
    const handleShow = (val) => {
        setCalc(val)
        setShow(true);
    }
    const calculatorList = [
        {
            calculatorNameMobile: 'mortgage calculator',
            calculatorNameDesktop: 'mortgage',
            type: 'mortgage',
        }, 
        {
            calculatorNameMobile: 'stamp duty calculator',
            calculatorNameDesktop: 'stamp duty',
            type: "stamp-duty"
        }, 
        // {
        //     calculatorNameMobile: 'Rental yield calculator',
        //     calculatorNameDesktop: 'Rental yield'
        // }, 
        // {
        //     calculatorNameMobile: 'click for mortgage help',
        //     calculatorNameDesktop: 'click for help'
        // }
    ]
    return ( 
        <React.Fragment> 
            <div className="property-calculator">
                <h3>property calculators</h3>
                <ul>
                    {calculatorList.map((item, i) => (
                        <li key={item.type}>
                            <button type='button' className='link_btn' onClick={() => {
                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push({
                                    'event': 'formSubmit',
                                    'formType': item.type,
                                    'formId': item.type,
                                    'formName': _.startCase(item.calculatorNameMobile),
                                    'formLabel': _.startCase(item.calculatorNameMobile),
                                });
                                handleShow(item.type)
                            }}>
                                <i className="icon-calculator"></i>
                                <span className='d-md-none'>{item.calculatorNameMobile}</span>
                                <span className='d-none d-md-block'>{item.calculatorNameDesktop}</span>
                                <i className="icon-arrow-right"></i> 
                            </button>
                        </li>
                    ))}
                </ul>
            </div> 
            <Modal className="calculator-popup" show={show} onHide={handleClose}> 
                <button onClick={handleClose} type="button" className="close-modal">
                    <i className="icon-close-modal"></i>
                </button> 
                <CalculatorForm
                    calc={calc}
                    {...props}
                /> 
            </Modal>
        </React.Fragment>
    );
}

export default ProductCalculator;