import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Row, Col, Button, Form } from 'react-bootstrap';
import {
  calculate,
  countries,
  // propertyTypes,
  // buyerTypes,
} from "@starberry/react-calculator-stampduty"
import { filterNumber, numberFormat } from "../utils";

const submitRef = React.createRef(null);

// we could user uk-ireland-stampduty-calculator's propertyTypes & buyerTypes
// but key val par diff we could get valus using Object.values but it is not
// supported in IE - https://caniuse.com/#feat=object-values
// so defined our our custom key pairs
const propertyTypes = [
    {
        value: "residential",
        label: "Residential",
    },
    {
        value: "commercial",
        label: "Commercial",
    },
]

const buyerTypes = [
    {
        value: "first",
        label: "First-time Buyer",
    },
    {
        value: "home",
        label: "Mover (not a first-time buyer)",
    },
    {
        value: "investor",
        label: "Second Home or Buy to Let",
    },
]

// use project specific import here

// DO NOT DO ANY CHNAGES - START
const StampdutyCalc = props => {
    const currency = props.currency
    const title = props.title

    const [result, setResult] = useState(false)
    const [showerror, setShowerror] = useState(false)

    const [propertyType, setPropertyType] = useState(props.propertyType)
    const [propertyValue, setPropertyValue] = useState(
        currency + numberFormat(filterNumber(props.propertyValue))
    )
    const [buyer, setBuyer] = useState(props.buyerType)

    const formatPrice = str => {
        return currency + str.toLocaleString("en-US")
    }

    const doCalculate = () => {
        const results = calculate(
        filterNumber(propertyValue), propertyType, countries.ENGLAND, buyer)
        // effective stamp duty rates
        // Formula => ((tax / price) * 100).
        // Ex: (213750/2500000) * 100 = 8.55%.
        let effectiveRate = (results.tax / filterNumber(propertyValue)) * 100
        effectiveRate = new Intl.NumberFormat("en-US", {}).format(effectiveRate)

        if(effectiveRate === "NaN"){
            effectiveRate = 0
        }

        let summaryBands = []
        results.summaryBands.map(result => {
            summaryBands.push(
                result.adjustedRate +
                "% between " +
                formatPrice(result.start) +
                " and " +
                formatPrice(result.end)
            )
        })
        const result = {
            effectiveRate: effectiveRate + "%",
            summaryBands: summaryBands,
            tax: formatPrice(results.tax),
        }
        setResult(result)
    }

    const handleSubmit = event => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Calculator',
            'formType': title,
            'formId': "Stamp Duty Calculator",
            'formName': "Stamp Duty Calculator",
            'formLabel': "Stamp Duty Calculator"
        });
        const form = event.currentTarget
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            setShowerror(true)
        } else {
            if(filterNumber(propertyValue) == 0) {
                setShowerror(true)
                setResult(false)
            }
            else {
                setShowerror(false)
                doCalculate()
            }
        }
    }

    const handleDeposit = event => {
        setPropertyValue(currency + numberFormat(filterNumber(event.target.value)))
    }

    const handleBuyer = event => {
        setBuyer(event.target.value)
    }

    useEffect(() => {
        submitRef.current.click();
    }, [buyer, propertyValue])

    // DO NOT DO ANY CHNAGES - END

    return (
        <React.Fragment>
            <div className="">
                {showerror && (
                    <div className="alert-danger">
                    <p>* Please enter Property Value amount</p>
                    </div>
                )}
                <Form
                    name="MortgageCalc"
                    noValidate
                    onSubmit={handleSubmit}
                >
                    <div className="form-field">
                        <input type="hidden" name="bot-field" />
                        <Form.Group controlId="deposit" className="field-input">
                            <Form.Label>Property Value *</Form.Label>
                            <Form.Control 
                                required
                                type="text"
                                name="deposit"
                                value={propertyValue}
                                placeholder="Purchase Price"
                                onChange={handleDeposit}
                            />
                        </Form.Group>

                        <Form.Group controlId="buyer" className="field-input">
                            <Form.Label>Buyer Type</Form.Label>
                            <Form.Control 
                                as="select"
                                className="form-control form-select"
                                required
                                value={buyer}
                                name="buyer"
                                placeholder="Buyer Type"
                                onChange={handleBuyer}
                            >
                                {buyerTypes.map((item, i) => 
                                    <option value={item.value} key={i}>
                                        {item.label}
                                    </option>
                                )}
                            </Form.Control>
                        </Form.Group>
                        <Button type="submit" ref={submitRef} className="btn btn-pink d-none">
                            Calculate
                        </Button>
                    </div>
                    <Col md={12}>
                        {result && (
                            <div className="calc-result">
                                <div className="total-calculate">
                                    <span className="form-label">Stamp Duty:</span>
                                    <span className="total-amount">{result.tax}</span>
                                    <div className="mt-2">
                                        <p className="mb-1">You have to pay stamp duty:</p>
                                        {result.summaryBands.map((sm, i) => {
                                            return <p className="mb-1">{sm}</p>
                                        })}
                                        <p className="mb-1">
                                            Your effective stamp duty rate is {result.effectiveRate}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Col>
                </Form>
            </div>
        </React.Fragment>
    )
}

StampdutyCalc.defaultProps = {
    propertyType: `residential`,
    propertyValue: `450000`,
    buyerType: `first`,
    currency: process.env.CURRENCY ? process.env.CURRENCY : "£",
}

StampdutyCalc.propTypes = {
    propertyType: PropTypes.string,
    propertyValue: PropTypes.any,
    buyerType: PropTypes.string,
    currency: PropTypes.string,
}

export default StampdutyCalc