import HTMLReactParser from 'html-react-parser'
import React,{useState} from 'react'
import './ProductDescription.scss'
const ProductDescription = (props) => {
    const [showContent, setShowContent] = useState(false)  
    return (
        <div className="description-block" id="features-description">
            <h3>general description</h3>
            <div className={showContent ? 'description-text': 'height-increase description-text'}>
                {props?.data?.long_description && 
                    <React.Fragment>
                        <p>{HTMLReactParser(props?.data?.long_description)}</p>
                    </React.Fragment>
                }
            </div>
            {/* <button type="button" className="read-more" onClick={() => setShowContent(true)}>
                Read More
            <i className="icon-caret-down"></i>
            </button> */}
        </div>
    );
}

export default ProductDescription;
