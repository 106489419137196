import React, { useState } from "react";
import {Form} from "react-bootstrap";
import { calculateMonthlyPayment } from "../calculators/calculators";
import { filterNumber } from "../utils";

const MortgageCalculator = (props) => {
    const [monthlyRepayment, setMonthlyPayment] = useState(0);
    //
    const [interestRate, setinterestRate] = useState(2.34)
    const handleChangeInterestRate = value => {
        setinterestRate(value)
    };

    const [downPayment, setdownPayment] = useState(25000);

    const handleChangeDownPayment = value => {
        setdownPayment(value)
    };

    const [loanValue, setloanValue] = useState(3000000);

    const handleChangeLoanValue = value => {
        setloanValue(value)
    };

    const [duration, setduration] = useState(10);
    const handleChangeduration = (e) => {
        setduration(parseInt(e.target.value))
    };

    const handleSubmit = () => {
        setMonthlyPayment(
            calculateMonthlyPayment(
                parseInt(filterNumber(loanValue)),
                parseFloat(interestRate),
                parseInt(filterNumber(downPayment)),
                duration
            )
        );

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Calculator',
            'formType': props?.property?.title,
            'formId': "Mortgage Calculator",
            'formName': "Mortgage Calculator",
            'formLabel': "Mortgage Calculator"
        });
    }

    React.useEffect(() => {
        handleSubmit();
    }, [loanValue, interestRate, duration, downPayment])

    React.useEffect(() => {
        if(props?.property) {
            setloanValue(props?.property?.price);
            setdownPayment((props?.property?.price/100)*10)
        }
        handleSubmit();
    }, []);

    return (
        <Form>
            <div className="form-field">
                {/* {formField.map((item) => (
                    <Form.Group className="field-input">
                        <Form.Label>{item.labelName}</Form.Label>
                        <Form.Control type="text" placeholder={item.inputValue}/>
                    </Form.Group>
                ))} */}
                <Form.Group className="field-input">
                    <Form.Label>Purchase Price (£):</Form.Label>
                    <Form.Control 
                        type="text"
                        value={loanValue}
                        onChange={(e) => {
                            const val = e.target.value.replace(",", "");
                            const re = /^[0-9\b]+$/;
                            if (re.test(val)) {
                                handleChangeLoanValue(val)
                            }
                        }}
                    />
                </Form.Group>
                <Form.Group className="field-input">
                    <Form.Label>Deposit Available (£):</Form.Label>
                    <Form.Control 
                        min={1000}
                        max={loanValue}
                        value={downPayment}
                        onChange={handleChangeDownPayment}
                    />
                </Form.Group>
                <Form.Group className="field-input">
                    <Form.Label>Duration (Years):</Form.Label>
                    <Form.Control 
                        type="number"
                        min={1}
                        max={25}
                        value={duration}
                        onChange={handleChangeduration}
                    />
                </Form.Group>
                <Form.Group className="field-input">
                    <Form.Label>Interest Rate (%):</Form.Label>
                    <Form.Control 
                        type="text" 
                        onChange={handleChangeInterestRate}
                        value={interestRate}
                        placeholder="£4,950,000"
                    />
                </Form.Group>
                <div className="total-calculate">
                    <span className="form-label">Monthly Repayment:</span>
                    <span className="total-amount">£{monthlyRepayment}</span>
                </div>
            </div>
        </Form>
    );
};
export default MortgageCalculator;
